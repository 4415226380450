.block-content{
	.box{
		margin-top: 15px;
		.box-title{
			margin-bottom: 5px;
			padding-bottom: 5px;
			&:after{
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				background: $light;
				margin-bottom: 15px;
				padding-bottom: 5px;
			}
			a{
				margin-left: 3px;
			}
		}
		.box-content{
			margin-bottom: 15px;
		}
	}
}


.block{
	margin-top: 15px;
	&:first-child{
		margin-top: 0;
	}
}



._block-content-loading{
	position: relative;
}
.abs-visually-hidden, .fieldset > .field.no-label > .label, .fieldset > .fields > .field.no-label > .label, .cart.table-wrapper .col.qty .label, .checkout-index-index .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .account .column.main .limiter > .label, .field.street .field.additional .label, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label{
	@extend %visuallyhidden;
}


.account{
	.main-content{
		.block-title{
			@extend .block-title-trk;
		}
	}

}


.trustpilot-addon{
	padding: 30px;
	border-top: 1px solid rgba(0,0,0,0.2);
	@include media-breakpoint-down(sm){
		padding: 30px 0;
	}
}