section.sidebar{
	background: $light;
	@include media-breakpoint-down(md){
		display: none;
		.account &{
			display: block;
		}
	}
	
	.sections{
		position: relative;
		@include media-breakpoint-up(lg){
			position: sticky;
			top: 110px;
		}
		.inner{
			padding: 30px;
			padding-bottom: 40px;
			@include media-breakpoint-down(sm){
				padding: 10px 0px;
				padding-bottom: 10px;
			}
			ul{
				list-style: none;
				padding-left: 40px;
				margin-bottom: 0;
				font-size: 1rem;
				@include media-breakpoint-down(lg){
					padding-left: 0;
					font-size: 0.9rem;
				}
				li{
					padding: 5px 0;
					color: $secondary-color;
					a{
						color: $secondary-color;
						font-weight: 600;
						display: block;
						span{
							display: inline-block;
							position: relative;
							&:after{
								background: $brand-primary;
								content: '';
								width: 100%;
								height: 3px;
								display: block;
								position: absolute;
								bottom: -5px;
								@include opacity(0);
								@include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
							}
						}

						&:hover{
							span{
								&:after{
									@include opacity(1);
									@include transform(translateY(-5px));
								}								
							}

						}
					}
					&.active{
						a{
							span{
								&:after{
									@include opacity(1);
									@include transform(translateY(-5px));
								}
							}
						}
					}
				}
			}
			.block-collapsible-nav{
				.title{
					display: none;
				}
				ul{
					padding-left: 20px;
					@include media-breakpoint-down(md){
						padding-left: 0;
					}
					li{
						position: relative;
						&:before{
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							width: 3px;
							background-color: $brand-primary;
							content: '';
							@include opacity(0);
						}
						a, strong{
							padding-left: 20px;

						}
						a{
							strong{
								padding-left: 0px;
							}
							&:hover{
								color: lighten($secondary-color,5%);
							}
						}
						&.current{
							&:before{
								@include opacity(1);
							}
						}
					}
				}
			}

		}
	}
}

.trustpilotheader{
	background: lighten($secondary-color, 10%);

	.inner{
		padding: 10px;
		svg{
			width: 136px;
		}
		// .icon{
		// 	display: inline-block;
		// 	vertical-align: top;
		// 	margin-right: 10px;
		// 	@include media-breakpoint-down(lg){
		// 		display: none;
		// 	}

		// 	svg{
		// 		width: 30px;
		// 		height: 30px;
		// 		margin-top: 5px;
		// 		path{
		// 			fill: #fff;
		// 		}
		// 		circle{
		// 			fill: darken($light, 10%);
		// 		}
		// 	}
		// }
		// .text{
		// 	display: inline-block;
		// 	vertical-align: top;
		// 	line-height: 40px;
		// 	letter-spacing: 0.05rem;
		// 	color: $black;
		// 	font-weight: 700;
		// 	font-size: 17px;
		// 	@include media-breakpoint-down(lg){
		// 		display: none;
		// 	}
		// }
	}
	// #sc6sSW{
	// 	float: right;
	// 	@include transform(translateY(-10px));
	// 	@include media-breakpoint-down(lg){
	// 		float: left;
	// 		width: 100%;
	// 		text-align: center;
	// 	}

	// }
}
.cat_selector{
	background: lighten($secondary-color,5%);
	color: $white;
	z-index: 9;
	position: relative;
	.inner{
		padding: 20px 30px;
		@include media-breakpoint-down(sm){
			padding: 15px 25px;
		}
		@include media-breakpoint-down(xs){
			padding: 15px;
			padding-top: 5px;
		}
		.categories{
			.text{
				font-size: 1rem;
				color: $white;
				font-weight: 700;
				@include media-breakpoint-down(xs){
					display: none;
				}

				@include media-breakpoint-up(lg){
					font-size: 1.07rem;
					line-height: 1;
					text-transform: uppercase;
				}

				span{
					font-weight: 700;
					
				}

			}

		}
	}


	.selected-item {
	  margin: 20px 0;
	  text-align: center;
	  p {
	    span {
	      font-weight: bold;
	    }
	  }
	}

	.dropdown-container{
		display: inline-block;
		position: relative;
		&.modelSel{
			margin-left: 25px;
			@include media-breakpoint-down(sm){
			    width: 100% !important;
			    margin-left: 0;
				.select2-container{
					width: 100% !important;
				}
			}
			@include media-breakpoint-down(xs){
				margin-left: 0;
				display: block;
				width: 100%;
			}
		}
	}


	.dropdown {
		width: 100%;
	  	position: relative;
	  	perspective: 800px;
	  	display: inline-block;
	  	margin-top: 8px;
	  	z-index: 9;

	}

}


.cat_selector--inner{
	@include media-breakpoint-down(sm){
		display: none;
	}
	@include media-breakpoint-up(md){
		display: block !important;
	}
}



.payment-method-title .label .action-help {
display: none !important
}