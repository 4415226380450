._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
    @include media-breakpoint-up(lg){
    	padding-right: 17px;
    }
}


.modals-overlay {
    background-color: rgba($black, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 899;
    ._has-modal {
	    left: 0 !important;
    }
}

.modal-slide{
    z-index: 900;
    bottom: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    max-height: 100vh;
    iframe{
		@include transform(translateX(100%));
		@include opacity(0);
    }
    &.sagepaysuiteserver-modal{
		width: 100%;
	    max-width: 400px;
	    margin: 0 auto;

	    .modal-inner-wrap{
	    	padding: 0 !important;
    		width: 100% !important;
    		margin: 8vh auto;
    		max-height: 100% !important;
    		iframe{
			    height: 80vh;
			    max-height: 475px;
			    border: 0;
			    width: 100%;
    		}
	    }
    }
    &._show{
		visibility: visible;
		iframe{
			@include transform(translateX(0));
			@include opacity(1);
		}
    }
}

.modal-popup {
    z-index: 900;
    left: 0;
    overflow-y: auto;
    bottom: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
	overflow-y: visible;
	h1.modal-title{
		font-weight: 600;
		color: $brand-primary;
		font-size: 2rem;
		@include media-breakpoint-down(sm){
			font-size: 1.5rem;
		}
	}
	&.confirm{
		text-align: center;
		.modal-content{
			font-weight: 600;
			color: $secondary-color;
		}
		.action-secondary{
			@extend .btn;
			@extend .btn-secondary;
			border-color: $secondary-color;
			color: $secondary-color;
			margin-right: 10px;
		}
		.action-primary{
			@extend .btn;
			@extend .btn-primary;
			background: $red;
			margin-left: 10px;
		}
	}
	.modal-footer{
		margin-top: auto;
		padding-left: 48px;
    	padding-right: 48px;
	    padding-bottom: 48px;
    	@include media-breakpoint-down(sm){
    		padding-bottom: 25px;
    		padding-left: 25px;
    		padding-right: 25px;
    	}
	}
	.modal-inner-wrap{
		max-height: 90%;
		max-width: 1000px;
		overflow-y: visible;
		@extend .d-flex;
		@extend .flex-column;
		background: $white;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}
    .modal-header{
    	padding-bottom: 19.2px;
    	padding-top: 48px;
    	padding-left: 48px;
    	padding-right: 48px;
    	@include media-breakpoint-down(sm){
    		padding-bottom: 15px;
    		padding-top: 25px;
    		padding-left: 25px;
    		padding-right: 25px;
    	}
    }
    .action-close{
	    background-image: none;
	    background: none;
	    box-sizing: content-box;
	    border: 0;
	    box-shadow: none;
	    line-height: inherit;
	    text-decoration: none;
	    text-shadow: none;
	    font-weight: 400;
	    display: inline-block;
	    text-decoration: none;
	    position: absolute;
	    right: 0;
	    top: 0;
    	padding: 17px 32px;
    	@include media-breakpoint-down(sm){
    		padding: 7px 5px;
    	}
    	&:before{
		    -webkit-font-smoothing: antialiased;
		    font-size: 32px;
		    line-height: 32px;
		    color: #383838;
		    content: '\e616';
		    font-family: $icons-font;
		    margin: 0;
		    display: inline-block;
		    font-weight: normal;
		    overflow: hidden;
		    speak: none;
		    text-align: center;
		    vertical-align: top;
		    @extend %transition;
    	}
    	span{
    		@extend %visuallyhidden;
    	}
    	&:hover{
    		&:before{
    			color: $brand-primary;
    		}
    	}
    }
    .modal-content{
    	overflow-y: auto;
    	padding: 0 41px 41px;
	    padding-left: 48px;
	    padding-right: 48px;
	    @include media-breakpoint-down(sm){
		    padding: 0 15px 12px;
		    padding-left: 15px;
		    padding-right: 15px;
	    }
    }


	.modal-inner-wrap{
		margin: 80px auto;
		width: 75%;
		@extend .d-flex;
		@extend .flex-column;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		@extend %transition;
		@include opacity(0);
		@include transform(translateY(-100px));
    	@include media-breakpoint-down(sm){
			margin: 10px auto;
			width: 100%;
    	}


	}



    &._show{
		visibility: visible;
		.modal-inner-wrap{
			@include transform(translateY(0));
			@include opacity(1);
		}
    }
    .modal-content{
    	#opc-new-shipping-address{
    		max-width: 600px;
    		fieldset.street{
    			legend.label{
    				font-weight: 600;
    				color: $secondary-color;
    			}
    			label.label{
    				display: none;
    			}
    		}
    		.field .control._with-tooltip input{
    			width: calc(100% - (54px + 10px));
    		}
    	}
    }
    .modal-footer{
    	.action-save-address{
    		margin-top: 15px;
    	}
    }

}



// LOG IN - REGISTER


.popup-authentication{
	.modal-inner-wrap{
		min-width: 768px;
    	width: 60%;
    	background: $white;

    	@include media-breakpoint-down(sm){
    		min-width: 100%;
    	}
	}
	.message{
		background: $red !important;
	}
	.action-close{
		&:hover{
			&:before{
				color: $red;
			}
		}
	}
	.block-authentication{
		@extend .d-flex;
		@extend .flex-row;

		@include media-breakpoint-down(sm){
			width: 100%;
			display: block !important;
		}
		.block{

		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical; 
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;
		    -webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		    flex-grow: 1;

		    box-sizing: border-box;
		    padding: 10px 30px 0 0;
			float: left;
			width: 50%;



			@include media-breakpoint-down(sm){
				width: 100%;
				display: block;
				padding: 10px 0px 0 0;
			}

			&.block-customer-login{
				border-left: 1px solid rgba(255,255,255,0.25);
			    border-top: 0;
			    margin: 0;
			    padding: 10px 0 0 40px;
			    position: relative;
			    @include media-breakpoint-down(sm){
			    	padding: 20px 0;
			    	border-left: 0;
			    }
			    &:before{
					height: 36px;
				    line-height: 34px;
				    margin: -17px 0 0 -18px;
				    min-width: 36px;
				    background: #fff;
				    border: 1px solid rgba(255,255,255,0.25);
				    border-radius: 50%;
				    box-sizing: border-box;
				    color: rgba(10, 10, 10, 0.41);
				    content: attr(data-label);
				    display: inline-block;
				    letter-spacing: normal;
				    padding: 0 0.2rem;
				    position: absolute;
				    text-align: center;
				    text-transform: uppercase;
				    left: 0;
				    top: 50%;
				    @include media-breakpoint-down(sm){
				    	content: none;
				    }
			    }
			}
		}

		.action.primary{
			background: $red;
			border-color: $red;
			color: $white;
			&:hover{
				background: $secondary-color;
				border-color: $secondary-color;
			}
		}
		.action.secondary{
			color: $secondary-color;
			&:hover{
				background: $secondary-color;
				color: $white;
				border-color: $secondary-color;
			}
		}
		.block-title{
			font-size: 1.2rem;
		    border-bottom: 0;
		    margin-bottom: 25px;
		    strong{
		    	font-weight: 700;
			    color: $secondary-color;
			    text-transform: uppercase;
		    }
		}
		.block-content{
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical; 
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;
		    -webkit-box-flex: 1;
		    -ms-flex-positive: 1;
		    flex-grow: 1;
		    @include media-breakpoint-down(sm){
		    	font-size: 0.9rem;
		    }
		    ul{
		    	list-style-position: inside;
		    	padding-left: 10px;
		    }
		}
		.actions-toolbar{
		    margin: inherit;
		    padding: 10px 0;
		    clear: both;
			margin-bottom: 0;
			margin-top: auto !important;
			float: left;
    		width: 100%;
    		padding-top: 22px;
			@include media-breakpoint-down(sm){
				padding-top: 12px;
			}
			.primary{
				float: right;
				margin-right: 0;
				@include media-breakpoint-down(sm){
					float: left;
				}
			}
			.secondary{
				float: left;
				margin-right: 10px;
				.action{
					font-size: 0.8rem;
				}
			}
		}
	}
}


.checkout-popup-wrapper{
	.modal-inner-wrap{
		max-width: 450px !important;
		min-width: 270px !important;
		width: 100% !important;
	}
	.block-customer-login{
		border-left: 0 !important;
		padding: 0px !important;
		&:before{
			display: none !important;
		}
	}
}
