.slider__home{
	width: 100%;
	height: auto;
	position: relative; 
    overflow: hidden;
	ul, li{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		width: 100%;
		overflow: hidden;
		img{
			width: 100%;
			height: auto;
			display: block;
		}
	}
}

.trustpilot-container {
	@include media-breakpoint-down(sm){
	    max-width: 220px;
	    margin: 0 auto;
	}

}

.text__home{
 	background: $secondary-color;
 	font-weight: 600;
 	padding: 40px;
 	padding: calc(40px + 1vw);
 	font-size: 1.2rem;
 	letter-spacing: 0.08rem;
 	@include media-breakpoint-down(lg){
 		padding: calc(30px + 1vw);
 		font-size: 1.1rem;
 	}
 	@include media-breakpoint-down(sm){
 		text-align: center;
 	}
 	h1{
	    font-weight: 600;
	    margin-top: 0;
	    font-size: 1.95rem;
	 	@include media-breakpoint-down(lg){
	 		font-size: 1.55rem;
	 	}

 	} 
 	.white{
 		color: $white;
 	}
 	.blue{
 		color: $lightblue;
 	}
 	.yellow{
 		color: yellow;
 	}
 	.red{
 		color: $brand-primary;
 	}
 	p{
 		margin-bottom: 5px;
	 	@include media-breakpoint-down(lg){
	 		margin-bottom: 0;
	 	}

 	}
}

.cms-home{
	footer.container-fluid{
		.trustpilot{
			@include media-breakpoint-up(md){
				display: none !important;
			}
			
		}

	}
	.text__home {
		.trustpilot-container{
			@include media-breakpoint-down(sm){
				display: none !important;
			}
		}
	}
}

 


.popup-exit{
	background: #00585a;
    color: white;
    padding: 50px 30px;
    min-height: 100px !important;
    border: 6px solid #ec5845;
}


.popup-first-line{
	font-weight: 600;
    font-size: 2.5rem;
    color: #ff0;
    text-transform: uppercase;
}

.popup-second-line{
    font-size: 1.7rem;
    max-width: 500px;
    margin: 0 auto;
    color: white;
    font-weight: 600;
}

.popup-voucher-code{
	font-size: 2.5rem;
    display: inline-block;
    padding: 10px 40px;
    margin: 30px 0;
    width: auto;
    background: white;
    border-style: dashed;
    border-width: 5px;
    border-color: #ec5845;
    color: #333;
    cursor: pointer;
    font-weight: 600;
    transition: all .3s;
    small{
    	font-size: 0.9rem !important;
    	color: #333;
    	margin-bottom: 0;
    	transition: all .3s;
    }
}

.popup-voucher-code:hover{
	background: #00585a;
	color: white;
	small{
		color: white;
	}
}

.popup-small-extra{
	color: white;
    font-size: 1.15rem;
}

.popup-copied{
	display: block;
    color: #ec5845;
    margin-bottom: 20px;
}