


// MULTISHIPPING CHECKOUT


.multicheckout{
	.table-wrapper{
		.col{
			width: auto;
			.label{
				display: none;
			}
		}
		.col.actions{
			a{
			    font-weight: 600;
			    text-align: right;
			    display: block;
			}
		}
		.col.qty{
			width: 70px;
		}
		.col.address{
			width: 50%;
		}
	}
	.table-caption{
		display: none;
	}
	.block-title{
		border-bottom: 1px solid $light;
    	padding-bottom: 10px;
    	margin-bottom: 20px;
    	font-size: 1.5rem;
    	strong{
    		span{
    			@include opacity(0.5);
    			font-size: 1rem;
    		}
    	}
	}

	.title{
    	strong{
    		span{
    			@include opacity(0.5);
    			font-size: 1rem;
    		}
    	}
	}
	.grand.totals{
		font-size: 1.5rem;
		text-align: right;
	}
	.box-items{
		margin-top: 40px;
	}
	.please-wait{
		display: block;
		width: 100%;
		margin: 20px;
		text-align: center;
	}
}


// CHECKOUT

.opc-progress-bar {
    margin: 30px 0 20px;
    counter-reset: i;
    font-size: 0;
    padding: 0;
    list-style: none none;
    @include media-breakpoint-down(sm){
    	margin: 15px 0;
    }
    li{
    	display: inline-block;
	    position: relative;
	    text-align: center;
	    vertical-align: top;
	    width: 50%;
    }
}
.opc-progress-bar-item{
    display: inline-block;
    padding-top: 0;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-family: inherit;
    font-style: inherit;
    font-weight: 300;
    line-height: inherit;
    font-size: 18px;
    &._complete{
    	cursor: pointer;
    }
    &:before{
    	background: #e4e4e4;
	    top: 19px;
	    content: '';
	    height: 7px;
	    left: 0;
	    position: absolute;
	    width: 100%;
    }
    &>span{
		display: inline-block;
	    padding-top: 45px;
	    width: 100%;
	    word-wrap: break-word;
	    color: #e4e4e4;
	    font-family: inherit;
	    font-style: inherit;
	    font-weight: 600;
	    line-height: inherit;
	    font-size: 18px;
	    @include media-breakpoint-down(xs){
	    	font-size: 14px
	    }
	    &:before, &:after{
			background: #e4e4e4;
		    height: 38px;
		    margin-left: -19px;
		    width: 38px;
		    border-radius: 50%;
		    content: '';
		    left: 50%;
		    position: absolute;
		    top: 0;
	    }
	    &:before{

	    }
	    &:after{
		    height: 38px;
		    margin-left: -19px;
		    width: 38px;
		    border-radius: 50%;
		    content: '';
		    left: 50%;
		    position: absolute;
		    top: 0;
			background: #fff;
		    height: 26px;
		    margin-left: -13px;
		    top: 6px;
		    width: 26px;
		    content: counter(i);
		    counter-increment: i;
		    color: $brand-primary;
		    font-family: inherit;
		    font-style: inherit;
		    font-weight: 600;
		    line-height: inherit;
		    font-size: 18px;
	    }
    }
    &._active, &._complete{
    	color: $brand-primary;
		&:before{
    		background: $brand-primary;
    	}
    	&>span{
    		color: $brand-primary;
    		&:before{
	    		background: $brand-primary;
	    	}
    		&:after{
				content: "";
			    font-family: "icons-blank-theme";
			    line-height: 1;
			    font-size: 28px;
    		}
    	}
    }
}
.opc-estimated-wrapper{
	display: none;
}

.opc-wrapper{
	padding-left: 30px !important;
	@include media-breakpoint-down(sm){
		padding-left: 0 !important;
	}
	.opc{
		margin: 0;
	    padding: 0;
	    list-style: none none;
	    li{
	    	margin: 0;
	    }
	}
	.step-title{
	    border-bottom: 1px solid $light;
	    padding-bottom: 10px;
	    color: $brand-primary;
	    font-style: normal;
	    font-weight: 600;
	    line-height: 1.42857;
	    font-size: 26px;
	}
	.step-content{
		margin: 0 0 40px;
	}

}
.authentication-wrapper{
	width: 100%;
	.login-message{
		width: 100%;
		margin: 25px 0 15px;
		text-align: center;
		span{
			font-size: 0.9rem;
		}
		button{
			margin-left: 15px;
			@include media-breakpoint-down(xs){
				display: block;
				margin: 11px auto 0;
			}
		}
	}
	.form-login{
		max-width: 500px;
		margin-top: 28px;
    	margin-bottom: 20px;
	}
}

.opc-summary-wrapper .modal-header .action-close{
	display: none;
}
#checkoutSteps{
	.action-show-popup {
		@extend .btn;
		@extend .btn-primary;
	}
	.methods-shipping{
		margin: 10px 0;
		.col{
			width: auto;
			cursor: pointer;
			&.col-method:first-child{
				max-width: 42px;
				.radio{
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					padding: 0;
					-webkit-print-color-adjust: exact;
					print-color-adjust: exact;
					display: inline-block;
					vertical-align: middle;
					background-origin: border-box;
					-webkit-user-select: none;
					-moz-user-select: none;
					user-select: none;
					flex-shrink: 0;
					position: relative;
					top: -1px;
					height: 1.25rem;
					width: 1.25rem;
					color: $brand-primary;
					border: 1px solid $gray;
					background-color: #fff;
					border-radius: 100%;
					margin: 0;
					cursor: pointer;
					&:checked {
						background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
						border-color: $brand-primary;
						background-color: $brand-primary;
					}
				}
			}
			&.col-price{
				font-weight: 600;
				color: $red;
				max-width: 80px;
			}
			&.col-carrier{
				display: none;
			}
		}
	}
	.table-checkout-shipping-method{
		@extend .table;
		@extend .table-sm;
	}
	.table-checkout-shipping-method thead th{
		display: none;
	}
	.form-login{
		margin-top: 30px;
		margin-bottom: 1rem;
	}
	.note{
		margin-bottom: 1rem;
	}
	.field:not(.street){
		@extend .row;
		&>.label{
			@extend .col-md-4;
			@extend .col-form-label;
		}
		&>.control{
			@extend .col-md-8;
		}
		.nested{
			margin-top: 1rem;
			.additional{
				.label{
					display: none;
				}
				.control{
					@extend .col-md-12;
				}
			}
		}

	}
	.street{
		@extend .row;
		&>.label{
			font-weight: 600;
			font-size: 16px;
			@extend .col-md-4;
			@extend .col-form-label;
		}
		&>.control{
			@extend .col-md-8;
			@extend .push-md-4;
			&>.field{
				&>.label{
					display: none;
				}
				.control{
					@extend .col-md-12;
				}
			}
		}
	}
	.payment-method-title{
		&.field{
			.label{
			    @extend .col-md-12;
				&:before{
					top: 0.17em;
				}
				// span{
				// 	display: none;
				// }
			}
		}
	}
	.paypal-payment-method-logo{
		display: inline-block;
	}
	.payment-method-content{
		position: relative;
		z-index: 0;
		.billing-address-same-as-shipping-block{
			label{
				span{
					padding-left: 0;
				    top: -2px;
				    position: relative;
				}
			}
		}
		.actions-toolbar{
			.action-update{
				@extend .btn;
				@extend .btn-secondary;
			}
			.action-cancel{
				@extend .btn;
				@extend .btn-simple;
			}
		}

		#billing-new-address-form{
			.choice{
					margin-left: 0;
					label{
					    padding-left: 30px;
					    padding-top: 0;
					    padding-bottom: 0;
					}
			}
		}

	}
	.discount-code {
		.actions-toolbar {
			margin-top: 0.5rem !important;
		}
		.payment-option-content{
			padding: 30px;
			background-color: $light;
		}
		.apply-discount-toggle{
			font-size: 18px;
			font-weight: 700;
			margin-top: 40px;
			position: relative;
			border-bottom: 1px solid $light;
			cursor: pointer;
			span{
				    line-height: 3.5rem;
				    display: block;
			}
			&:after{
			    -webkit-font-smoothing: antialiased;
			    font-size: 30px;
			    line-height: 12px;
			    color: inherit;
			    content: "";
			    font-family: "icons-blank-theme";
			    margin: 3px 0 0;
			    display: inline-block;
			    font-weight: normal;
			    overflow: hidden;
			    speak: none;
			    text-align: center;
			    vertical-align: middle;
			    position: absolute;
			    right: 0;
			    top: 1.5rem;
			}

		}
		&._active{
			.apply-discount-toggle{
				&:after{
					content: "";
				}
			}
		}
	}

	.shipping-address-item {
	    border: 2px solid $light;
	    font-size: 14px;
	    line-height: 30px;
	    margin: 0 0 20px;
	    padding: 20px 35px 20px 20px;
	    transition: 0.3s border-color;
	    width: 50%;
	    display: inline-block;
	    position: relative;
	    vertical-align: top;
	    word-wrap: break-word;
		.action {
		    margin-top: 15px;
		}


	    .action-select-shipping-item{
	    	float: right;
	    }
	    &.selected-item{
	    	border-color: $brand-primary;
	    	&:after{
			    background: $brand-primary;
			    color: #ffffff;
			    content: '\e610';
			    font-family: 'icons-blank-theme';
			    height: 27px;
			    width: 29px;
			    font-size: 19px;
			    line-height: 21px;
			    padding-top: 2px;
			    position: absolute;
			    right: 0;
			    text-align: center;
			    top: 0;
	    	}
	    	.action-select-shipping-item{
	    		display: none;
	    	}
	    }
	}


}




.opc-wrapper .form-login .fieldset .note, .opc-wrapper .form-shipping-address .fieldset .note {
    line-height: 12px;
}


.opc-sidebar {
    margin: 46px 0 20px;
    width: 66.66667%;
    float: right;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    @include media-breakpoint-down(sm){
    	margin: 0px 0 10px;
    }
}

.opc-block-summary, .opc-block-shipping-information{
    padding: 22px 30px 22px;
    margin: 0 0;
    @include media-breakpoint-down(xs){
    	padding: 0 15px 10px;
    	.table-totals{
    		th,td{
    			width: 100%;
    			display: block;
    		}
    	}
    }
    &>.title{
    	display: block;
	    padding-bottom: 10px;
	    color: $brand-primary;
	    font-weight: 700;
	    font-size: 20px;
    }
    .items-in-cart{
    	&>.title{
		    padding: 0 40px 10px 0;
		    cursor: pointer;
		    display: block;
		    text-decoration: none;
		    margin-bottom: 0;
		    position: relative;
		    strong{
			    font-size: 18px;
			    margin: 0;
		    }
		    &:after{
			    -webkit-font-smoothing: antialiased;
			    font-size: 30px;
			    line-height: 12px;
			    color: inherit;
			    content: "";
			    font-family: "icons-blank-theme";
			    margin: 3px 0 0;
			    display: inline-block;
			    font-weight: normal;
			    overflow: hidden;
			    speak: none;
			    text-align: center;
			    vertical-align: middle;
			    position: absolute;
			    right: 0;
			    top: 10px;
		    }
    	}
    	&.active{
    		&>.title{
    			&:after{
    				content: "";
    			}
    		}
    	}
    	.minicart-items-wrapper .product-item{
    		padding: 20px;
    		.subtotal{
    			font-weight: 600;
    			color: $brand-primary;
    		}
    		.details-qty{
    			.label{
    				@include opacity(0.5);
    				font-size: 0.8rem;
    			}
    		}
    	}
    }
    .shipping-information-content{
	    padding: 10px 30px;
	    font-size: 1rem;
    }
    .ship-via{
    	margin-top: 15px;
    }
    .shipping-information-title{
	    display: block;
	    padding-bottom: 10px;
	    color: $brand-primary;
	    font-weight: 700;
	    font-size: 20px;
	    border-bottom: 1px solid $light;
	    .action{
			background: transparent;
    		border: 0;
    		box-shadow: none;
    		float: right;
			&:before{
			    -webkit-font-smoothing: antialiased;
			    font-size: 35px;
			    line-height: 20px;
			    color: $brand-primary;
			    content: '\e608';
			    font-family: 'icons-blank-theme';
			    vertical-align: middle;
			    display: inline-block;
			    font-weight: normal;
			    overflow: hidden;
			    speak: none;
			    text-align: center;
	    	}
	    	span{
	    		@extend %visuallyhidden;
	    	}
	    	&:hover{
	    		&:before{
	    			color: $secondary-color;
	    		}
	    	}
	    }
    }
}





// COPIED


.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .cart.table-wrapper .col.qty .label, .checkout-index-index .page-title-wrapper,
.checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .account .column.main .limiter > .label, .field.street .field.additional .label, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }


.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):before, .form-create-return .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .toolbar:before, .toolbar-products:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after {
  content: '';
  display: table; }



.checkout-payment-method .payment-method-content {
  display: none;
  padding: 12px 45px;
   background: $light;
   @include media-breakpoint-down(xs){
   	padding: 12px 30px;
   }

}
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }


tr.totals.shipping.excl .label{
	display: none;
}


#shipping-new-address-form, #billing-new-address-form{
	fieldset.street{
		.iexplorer &{
			display: block;
		}
	}
}


.payment-method-note{
	display: none;
}

#dd {
	display: block;
	position: relative;
    width: 100%;
    font-size: 1rem;
    line-height: 1.25;
    color: #00585a;
    margin: 20px 0;
    height: calc(2.18rem + 5px);
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0.15);
    @extend %transition;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    &:after{
	    content: "";
	    width: 20px;
	    height: 20px;
	    background-image: url(../images/arrow-blue.svg);
	    position: absolute;
	    background-size: 26px 15px;
	    background-repeat: no-repeat;
	    right: 16px;
	    top: 11px;
	    z-index: 0;
	    @extend %transition;
    }
    .title__selected{
    	padding: 0.5rem 2rem 0.5rem 0.75rem;
    	display: block;
	    position: relative;
	    z-index: 1;
		white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    }
	.dropdown {
	    position: absolute;
	    top: calc(100% + 2px);
	    z-index: 5;
	    padding-left: 0;
	    left: -2px;
	    list-style: none;
	    margin-bottom: 0;
	    right: -2px;
	    background: #fff;
	    list-style: none;
	    font-weight: normal;
	    display: none;
	    height: auto;
	    max-height: 175px;
	    overflow-y: auto;
	    border: 1px solid #007174;
	    li{
	    	a{
	    		font-weight: 300;
	    		text-decoration: none;
	    		display: block;
	    		padding: 10px 13px;
	    		color: #464a4c;
	    		font-size: 0.9rem;
	    		@include transition(none);
	    		&:hover{
				    background: #1e90ff;
				    color: $white;
	    		}
				.price{
				    &:before{
				    	content: ' - ';

				    }
				    .price{
				    	&:before{
				    		content: '';
				    	}
				    }

				}

	    	}
	    }
	}
	&.active{
		border-color: #ec5845;
		.dropdown{
			display: block;
		}
	}
}





/* No CSS3 support */

.no-opacity       #dd .dropdown,
.no-pointerevents #dd .dropdown {
    display: none;
    opacity: 1; /* If opacity support but no pointer-events support */
    pointer-events: auto; /* If pointer-events support but no pointer-events support */
}

.no-opacity       #dd.active .dropdown,
.no-pointerevents #dd.active .dropdown {
    display: block;
}

.paypal-review{
	.paypal-review-discount{
		margin-bottom: 30px;
		.fieldset.coupon {
		    background: $light;
		    padding: 20px;
		}
		.title{
		    border-top: 1px solid #d1d1d1;
		    cursor: pointer;
		    font-weight: 600;
		    display: block;
		    text-decoration: none;
		    margin-bottom: 0;
		    overflow: hidden;
		    padding: 7px 30px 7px 5px;
		    position: relative;
		    &:after{
			    -webkit-font-smoothing: antialiased;
			    font-size: 30px;
			    line-height: inherit;
			    color: inherit;
			    content: "";
			    font-family: "icons-blank-theme";
			    margin: inherit;
			    display: inline-block;
			    font-weight: normal;
			    overflow: hidden;
			    speak: none;
			    text-align: center;
			    vertical-align: middle;
			    position: absolute;
			    right: -5px;
			    top: -5px;
		    }
		}
		.active{
			.title{
				&:after{
					 content: "";
				}
			}
		}

	}
	.paypal-review-items{
		.paypal-review-title{
		    border-top: 1px solid #d1d1d1;
		    padding: 7px 30px 7px 5px;
			a{
				float: right;
				font-weight: 700;
				@include media-breakpoint-down(xs){
				    float: none;
				    display: block;
				    margin: 10px 0;
				}
			}
		}
		.table-wrapper{
			padding: 0 0;
			margin-bottom: 0;
		}
		.table-paypal-review-items{
		    padding: 10px 20px;
		    margin-bottom: 0;
		    margin-top: 20px;
		    background: $light;
		    display: block;
		    @include media-breakpoint-down(sm){
		    	margin-top: 10px;
		    	thead{
		    		display: none;
		    	}
		    	tbody.item{
					padding: 5px 0;
				    display: block;
		    	}
		    	.table tbody + tbody{
		    		border-top: 2px solid #e4e4e4;
		    	}

		    	.item{
		    		.product-item-name{
		    			    margin-top: 0;
		    		}
		    		.col{
		    			padding: 3px 0;
		    			display: block;
		    			&:before{
							padding-right: 10px;
						    content: attr(data-th) ": ";
						    display: inline-block;
						    color: #000;
						    font-weight: 700;
		    			}
						.product-item-photo{
							display: none !important;
						}


		    		}
		    	}
		    	.opc-block-summary{
				    display: block;
				    padding: 0;
				    margin-top: 20px;
				    tr{
						display: block;
						width: 100%;
				    }
					th.mark {
					    display: inline-block;
					    width: 65%;
					    padding: 8px 0;
					    background: transparent;
					}
					td.amount {
					    display: inline-block;
					    width: 30%;
					    text-align: right;
					}
		    	}
		    }
		}
	}
}
