*{
    outline: 0 !important;
}

body, html {
	font-family: "proxima-nova", sans-serif;
	letter-spacing: 0.02rem;
}

body{
	overflow-x: hidden;
    width: 100%;
    margin: 0;
    background: darken($secondary-color,10);
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
}

main.main{
	max-width: 100%;
	width: 100%;
	overflow-x: hidden;

}

.page-wrapper, .message.demo{
    background-color: $white;
}

.container{
	position: initial;
}
a, button{
	@include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
	&:after{
		@include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
	}
	&:hover, &:focus{
		text-decoration: none;
		outline: 0;
	}
}

p{
	&:last-child{
		margin-bottom: 0;
	}
}


.fade-in{
	@include opacity(0);
	@include transition(all 1s ease-in-out);
	&.show{
		@include opacity(1);
		@include transition(all 1s ease-in-out);
	}
	&.banner{
		@include transition(opacity 1s ease-in-out);
		&.show{
			@include transition(opacity 1s ease-in-out);
		}
	}
}





.ui-dialog{
	display: none;
}


@font-face {
    font-family: $icons-font;
    src: url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot');
    src: url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2') format('woff2'),
         url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff') format('woff'),
         url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.ttf')  format('truetype'),
         url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.svg#Blank-Theme_Icons') format('svg');
}


//
// nanoScrollerJS (Sass)
// --------------------------------------------------
/** initial setup **/
$nanoClass: "nano" !default;
$paneClass: "nano-pane" !default;
$sliderClass: "nano-slider" !default;
$contentClass: "nano-content" !default;

.#{$nanoClass} {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .#{$contentClass} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        overflow-x: hidden;

        &:focus {
            outline: thin dotted;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    > .#{$paneClass} {
        width: 10px;
        background: rgba(0,0,0,.25);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: .2s;
           -moz-transition: .2s;
             -o-transition: .2s;
                transition: .2s;
        -webkit-border-radius: 5px;
           -moz-border-radius: 5px;
                border-radius: 5px;
        visibility: hidden\9; // Target only IE7 and IE8 with this hack
        opacity: .01;

        > .#{$sliderClass} {
            background: #444;
            background: rgba(0,0,0,.5);
            position: relative;
            margin: 0 1px;
            -webkit-border-radius: 3px;
               -moz-border-radius: 3px;
                    border-radius: 3px;
        }
    }
}

.has-scrollbar > .#{$contentClass}::-webkit-scrollbar {
    display: block;
}

.#{$paneClass} {
    .#{$nanoClass}:hover > &,
    &.active,
    &.flashed {
        visibility: visible\9; // Target only IE7 and IE8 with this hack
        opacity: 0.99;
    }
}


.page-title-wrapper, .breadcrumbs{
    padding: 30px;
    @include media-breakpoint-down(xs){
        padding: 19px 15px;
    }
}


.page-title{
    color: $brand-primary;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 0;
}


.no-list{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.no-display{
    display: none;
}

.padd-right{
    padding: 0 15px;
}
.padd-top{
    padding-top: 22px;
}
body.slideopen{
    overflow: hidden;
}
.overlay_slides{
    position: fixed;
    z-index: 9;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include opacity(0);
    pointer-events: none;
    @extend %transition;
    .iexplorer &{
        display: none;
    }
    .slideopen &{
        @include opacity(1);
        pointer-events: initial;
        cursor: pointer;
    }
    .show-menu &{
        @include media-breakpoint-down(md){
            @include opacity(1);
            pointer-events: initial;
            cursor: pointer;
        }
    }

}


.main-content{
    padding: 0 0;
}


.content__main{
    padding: 0 30px 50px;
    font-size: 1.1rem;
    color: $secondary-color;
    font-weight: 400;

    @include media-breakpoint-up(lg){
        padding-right: 10vw;
    }
    @include media-breakpoint-down(lg){
        font-size: 1rem;
    }
    @include media-breakpoint-down(xs){
        padding: 0 15px 30px;
        word-break: break-word;
        font-size: 0.9rem;
    }



    .checkout-index-index &, .checkout-cart-index &{
        padding: 0px 0px !important;
    }
    .page-products &, .cms-shop &{
        padding: 0px 0px !important;
    }
    .cms-home &{
        display: none;
    }


    h1,h2,h3,h4,h5,h6{
        font-weight: 700;
    }

}





.block-title-trk{
    color: $brand-primary;
    font-weight: 600;
    font-size: 1.1rem;
    &:after{
        display: block;
        width: 50px;
        height: 2px;
        content: '';
        background: $brand-primary;
        margin: 10px 0 20px;
    }
}


input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    .loader{
        text-indent: -999999rem;
        img{
            bottom: 0;
            left: 0;
            margin: auto;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
        }

    }
}