.login-container .block-new-customer{
	@include media-breakpoint-down(sm){
		margin-top: 20px;
	}
}


// ORDER HISTORY

	.account{
		@include media-breakpoint-down(xs){
			.table-order-items{
				tfoot{
					display: block;
					th,td,tr{
						
							display: block;
							width: 100%;
					}

				}
			}
		}
		.table{
			font-size: 1rem;
			@include media-breakpoint-down(sm){
				display: inline-block;

			}
			> thead{
					@include media-breakpoint-down(md){
						border-top: 1px solid $light;
					}
			}
			.col{
				width: auto !important;
				&.actions{
					white-space: nowrap;
					text-align: right;
				}
			}
			> tbody{
				> tr{
					> td{
						@include media-breakpoint-up(lg){
							border-top: 1px solid $light;
						}
					}
				}
			}
			.action{
				font-weight: 600;
				&:after{
				    border-left: 1px solid #a6a6a6;
				    content: '';
				    display: inline-block;
				    height: 12px;
				    margin: 0 10px;
				    vertical-align: -1px;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}

			}
		}
		.toolbar-amount{
			font-size: 0.9rem;
		    text-align: right;
		}
	}

	@include media-breakpoint-up(lg){
		.abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-giftregistry-results .pages, .toolbar-wishlist-results .pages {
		    position: absolute;
		    width: 100%;
		    z-index: 0;
		    top: 0;
		    text-align: center;
		}
		.abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
		    line-height: 50px;
		    padding: 0;
		}
		.abs-pager-toolbar .toolbar-amount, .abs-pager-toolbar .limiter, .account .toolbar .toolbar-amount, .account .toolbar .limiter, .toolbar-giftregistry-results .toolbar-amount, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .toolbar-amount, .toolbar-wishlist-results .limiter {
		    position: relative;
		    z-index: 1;
		    float: right;
		}
		.abs-pager-toolbar, .account .toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results {
		    position: relative;
		}
	}


	.pages > .label {
	    border: 0;
	    clip: rect(0, 0, 0, 0);
	    height: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    width: 1px;
	}

	.pages .items {
	    font-size: 0;
	    letter-spacing: -1px;
	    line-height: 0;
	    white-space: nowrap;
	    margin: 0;
	    padding: 0;
	    list-style: none none;
	    display: inline-block;
	    font-weight: 600;
	}

	.pages .item {
	    font-size: 1rem;
	    letter-spacing: normal;
	    line-height: 30px;
	    margin: 0 15px 0 0;
	    display: inline-block;
	}
	.pages a.page {
	    display: inline-block;
	    padding: 0 5px;
	    text-decoration: none;
	}
	.pages strong.page {
	    background: $brand-primary;
	    font-size: 1rem;
	    letter-spacing: normal;
	    line-height: 30px;
	    color: $white;
	    display: inline-block;
	    font-weight: 700;
	    padding: 0 10px;
	}

	.pages .item .label {
	    border: 0;
	    clip: rect(0, 0, 0, 0);
	    height: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    width: 1px;
	}

	.pages .action.next, .pages .action.previous {
	    display: inline-block;
	    text-decoration: none;
	    @extend .btn;
	    @extend .btn-simple;
	}

	.pages .action.next:before {
	    -webkit-font-smoothing: antialiased;
	    font-size: 1.5rem;
	    line-height: inherit;
	    color: $brand-primary;
	    content: '\e608';
	    font-family: 'icons-blank-theme';
	    margin: 0;
	    vertical-align: top;
	    display: inline-block;
	    font-weight: normal;
	    overflow: hidden;
	    speak: none;
	    text-align: center;
	}
	.pages .action.previous:before {
	    -webkit-font-smoothing: antialiased;
	    font-size: 1.5rem;
	    line-height: inherit;
	    color: $brand-primary;
	    content: '\e617';
	    font-family: 'icons-blank-theme';
	    margin: 0;
	    vertical-align: top;
	    display: inline-block;
	    font-weight: normal;
	    overflow: hidden;
	    speak: none;
	    text-align: center;
	}
	.pages .action.next:visited:before {
	    color: $secondary-color;
	}
	.pages .action.previous:visited:before {
	    color: $secondary-color;
	}
	.pages .action.next > span, .pages .action.previous > span {
	    border: 0;
	    clip: rect(0, 0, 0, 0);
	    height: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    width: 1px;
	}



// ORDER VIEW
	.sales-order-view, .sales-order-shipment, .sales-order-creditmemo{

		.page-title-wrapper .page-title {
			display: inline-block;
		    font-size: 1.6rem;
		    line-height: 38px;
		}


		.return-status, .order-status {
		    border: 2px solid $light;
		    display: inline-block;
		    margin: 3px 0 0;
		    padding: 2px 10px;
		    text-transform: uppercase;
		    vertical-align: top;
		    margin-left: 20px;
		    color: $brand-primary;
		    font-weight: 600;
		    font-size: 1rem;
		    @include media-breakpoint-down(sm){
		    	display: block;
		    	margin: 4px 0;
		    }
		}
		.order-date{
			color: $secondary-color;
			date{
				font-weight: 600;
			}
		}
		.action.order{
			@extend .btn;
			@extend .btn-secondary;
		}
		.items {
		    margin: 0;
		    padding: 0;
		    list-style: none none;
		}
		.order-links{
			@extend %clearfix;
			.item {
			    line-height: 40px;
			    margin: 0 0 -1px;
			    float: left;
			    color: $brand-primary;
			    @include media-breakpoint-down(sm){
			    	width: 100%;
			    }
			    strong{
					background: $white;
				    border: 1px solid darken($light,5);
				    border-bottom: 0;
				    display: block;
				    font-weight: 700;
				    padding: 0 20px;
					margin-bottom: -1px;
				    padding: 1px 35px 2px 35px;
			    }
			    a{
			    	border: 0;
			    	border-bottom: 1px solid darken($light,5);
			    	background: #fafafa;
				    padding: 0 20px;
					margin-bottom: -1px;
				    padding: 1px 35px 2px 35px;
				    font-weight: 700;
				    display: block;
			    	&:hover{
			    		color: $white;
			    		background: $secondary-color;
			    	}
			    }
			}
		}
		.block-order-details-view{
			.block-content{
				.box-content{
					font-size: 1rem;
				}
			}
		}
		.order-details-items {
		    border: 1px solid darken($light,5);
		    margin-bottom: 40px;
		    padding: 25px;
		    font-size: 1rem;
		    @include media-breakpoint-down(sm){
		    	padding: 0;
		    	font-size: 0.9rem;
		    }
		    .actions-toolbar{
		    	display: none;
		    }
			.order-title {
			    display: none;
			}
			.table-wrapper {
			    margin: 0;
			}

			.table-wrapper:not(:last-child) {
			    margin-bottom: 30px;
			}
			th.col.subtotal, td.col.subtotal{
				text-align: right;
			}
			td.price, td.subtotal{
				font-weight: 600;
				color: $brand-primary;
			}

			.items-qty{
				list-style: none;
				white-space: nowrap;
				padding-left: 0;
				margin-bottom: 0;
				.title:after{
					content: ': ';
				}
			}
			tfoot{
				text-align: right;
				th{
					text-align: right;
				}
				tr.grand_total{
					font-size: 1.25rem;
					.amount{
						color: $brand-primary;
					}
				}
			}


		}



	} 
