footer.container-fluid{
	background: #1c1c1c;
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
	letter-spacing: 0.08rem;
	@include media-breakpoint-down(lg){
		padding-top: 40px;
		padding-bottom: 40px;
	}
	ul.links{
		list-style: none;
		margin-bottom: 0;

		li{
			display: inline-block;
			margin: 0 20px;
			a{
				text-transform: uppercase;
				color: $white;
				&:hover{
					color: $brand-primary;
				}
			}
			strong{
				text-transform: uppercase;
				color: rgba($white, 0.2);
				font-weight: 400;
			}
		}
	}
	.copyright{
		color: $white;
		margin-top: 10px;
		color: rgba($white, 0.6);
	}
	.trustpilot{
		margin-bottom: 40px;
		@include media-breakpoint-down(lg){
			margin-bottom: 20px;
		}
	}
}


/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	z-index:9999;
	background-color: #1c1c1c;
	color: #ffffff;
	padding: 30px 50px;
	&.show{
		display: block;
	}
	h6 {
		font-size: 25px;
		line-height: 25px;
		margin: 30px 0 10px;
	}
	p {
		font-size: 12px;
		line-height: 12px;
		margin: 0;
	}
	#btnUpdateBrowser {
		display: block;
		position: relative;
		padding: 10px 20px;
		margin: 30px auto 0;
		width: 230px; 
		color: #ffffff;
		text-decoration: none;
		border: 2px solid #ffffff;
		cursor: pointer;
		&:hover {
			color: $brand-primary;
			background-color:#ffffff;
		}
	}
	.last {
		position: absolute;
		top: 10px;
		right: 25px;
		width: 20px;
		height: 20px;
		&[dir='rtl'] {
			right: auto !important;
			left: 25px !important;
		}
	}
	#btnCloseUpdateBrowser{
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: #ffffff;
		font-size: 36px;
		line-height: 36px;
	}
	* html & {
		position: absolute;
	}
}

