.btn{
	border: 3px solid;
	border-radius: 0;
	outline: 0 !important;
	box-shadow: none !important;
	font-weight: 600;
	cursor: pointer;
	@include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
	&:disabled{
		cursor: default;
		@include opacity(0.3);
	}
}
button{
	cursor: pointer;
}
.btn-primary{
	border-color: $brand-primary;
	background: $brand-primary;
	color: $white;
	&:focus, &:hover{
		background: $secondary-color;
		border-color: $secondary-color;
		color: $white;
	}
}
.btn-secondary{
	border-color: $secondary-color;
	background: transparent;
	color: $secondary-color;
	&:focus, &:hover{
		background: $secondary-color;
		color: $white;
		border-color: $secondary-color;
	}
	&.btn-light{
		font-weight: 400;
		border: 1px solid $secondary-color;
		&:focus, &:hover{
			border: 1px solid $secondary-color;
		}

	}
}
.btn-simple{
	border-color: transparent;
	background: transparent;
	color: $brand-primary;
	&:focus, &:hover{
		background: transparent;
		color: $secondary-color;
		border-color: transparent;
	}
}
.action.back{
	@extend .btn;
	@extend .btn-secondary;
}
.action.action-delete{
	@extend .btn;
	@extend .btn-secondary;
	@extend .btn-light;
}
.btn-close{
	background: transparent;
	border: 0;
	outline: 0 !important;
	box-shadow: 0 !important;
	cursor: pointer;
	svg{
		width: 100%;
		height: 100%;
	}
}

.action{

	&.primary{
		background: $brand-primary;
		color: $white;
		border-style: solid;
		border-color: $brand-primary;
		text-transform: uppercase;
		padding: 0.65rem 1.5rem;
		letter-spacing: 2px;
		@include media-breakpoint-down(lg){
			font-size: 0.85rem;
			padding: 0.65rem 1.1rem;
		}
		&:hover, &:focus{
			border-color: $secondary-color;
			background: $secondary-color;
		}
	}
	&.secondary{
		background: $white;
		border-style: solid;
		color: $brand-primary;
		border-color: $brand-primary;
		text-transform: uppercase;
		padding: 0.65rem 1.5rem;
		letter-spacing: 2px;
		@include media-breakpoint-down(lg){
			font-size: 0.85rem;
			padding: 0.65rem 1.1rem;
		}
		
		&:hover, &:focus{
			border-color: $brand-primary;
			background: $brand-primary;
			color: $white;
		}
	}
}



.actions-toolbar{
    clear: both;
    width: 100%;
    overflow: auto;

    @extend .mt-4;
    
    .primary, .secondary{
    	display: inline-block;
    	vertical-align: middle;
    }
    .primary{
    	float: right;
    	@extend %clearfix;
    }
    .secondary{
    	float: left;
    	@extend %clearfix;
    }

}




// COPY OF DEFAULT


.abs-shopping-cart-items .action.update:before, .cart-container .form-cart .action.update:before{
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top;
    line-height: 1.2rem;
}
.abs-shopping-cart-items .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: top;
    line-height: 1.2rem;
}