.products-grid, .products-list{
	ol{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}
}

.prices-tier{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	@include media-breakpoint-down(lg){
		font-size: 0.92rem;
	}
	@include media-breakpoint-down(sm){
		font-size: 0.8rem;
	}
}


.empty-products{
	padding: 30px;
	@include media-breakpoint-down(lg){
		padding: 20px 30px;
	}
	@include media-breakpoint-down(md){
		padding: 20px 25px;
	}
}
.main-content{
	&.catalog-view{
		padding: 0 0;

		.page-title-wrapper, .toolbar-products{
			display: inline-block;
			vertical-align: top;
			@extend .col-12;
			@extend .col-md-6;
			float: left;
			padding: 30px;
			@include media-breakpoint-down(lg){
				padding: 20px 30px;
			}
			@include media-breakpoint-down(md){
				padding: 20px 25px;
			}

		}
		&.crosssell{
			.page-title-wrapper{
				.checkout-index-index &{
					padding-bottom: 40px !important;

				}
			}
		}
		.toolbar-products{
			padding: 25px 30px;
			@include media-breakpoint-down(lg){
				padding: 15px 30px;
			}
			@include media-breakpoint-down(md){
				display: none;
			}
			.modes{
				float: right;
				.modes-mode{
					&:before{
						color: #bababa;
						font-size: 28px;
						@extend %transition;
					}
					&:hover, &.active{
						&:before{
							color: $black;
						}
					}

				}
			}
		}
		.products.wrapper{
			clear: both;
		}
	}
}


.product-item-photo{
	text-align: center;
	@include media-breakpoint-up(lg){
		text-align: left;
		.product-image-container{
			margin-right: 15px;
			display: block;
		}
	}

}

.product-item-details{
	width: 100%;

	@include media-breakpoint-down(xs){
		text-align: center;
		.align-self-end{
			width: 100%;
		}
	}

	.product-item-actions{
		margin-bottom: 15px;
		@include media-breakpoint-down(lg){
			margin-bottom: 0;
		}
	}
	.product-item-description{
		font-weight: 600;
		color: $secondary-color;
		margin-top: 15px;
		font-size: 1.2rem;
		@include media-breakpoint-down(lg){
			font-size: 1.1rem;
		}
		p{
			margin-bottom: 0;
		}
		span{
			font-weight: 300;
			font-size: 1rem;
			display: block;
		}
	}
	.field.qty{
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0;
		margin-right: 5px;
		label{
			margin-right: 5px;
			@include media-breakpoint-down(sm){
				display: none;
			}
		}
		.control{
			display: inline-block;
			vertical-align: middle;
			.qty{
				height: 46px;
				width: 55px;
				background: $light;
				border-color: $secondary-color;
				@include media-breakpoint-down(lg){
					font-size: 0.9rem;
					height: 43px;
					width: 40px;
					padding: 0.5rem 5px;
				}
			}
		}
	}
	.product-item-name{
		display: block;
		font-weight: 700;
		color: $brand-primary;
		margin-top: 5px;
		font-size: 1.1rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		@include media-breakpoint-down(lg){
			font-size: 1rem;
		}
	}	
	.price-box{
		margin-top: 15px;
		color: $secondary-color;
		.item_price{
			letter-spacing: 0;
		}
		.price-including-tax{
			color: rgba(0,0,0,0.5);
			font-size: 0.85rem;

			@include media-breakpoint-down(lg){
				font-size: 0.8rem;
			}
			@include media-breakpoint-down(sm){
				font-size: 0.7rem;
			}

			&:before{
				content: '(';
				display: inline;
				margin-left: 5px;
			}
			&:after{
				content: ')';
				display: inline;
				margin-right: 5px;
			}


		}

	}
	.delivery, .from{
		text-transform: uppercase;
		font-weight: 600;
		color: $lightblue;
		display: block;
		font-size: 1.15rem;
		@include media-breakpoint-down(lg){
			font-size: 0.9rem;
		}
	}
	.from{
		color: $brand-primary;
	}
}



.products-list{

	.name-cat{
	    font-weight: 600;
	    font-size: 1rem;
	    padding: 20px 30px;
	    &:first-child{
	    	padding-top: 0;
	    }
	    @include media-breakpoint-down(md){
	    	padding: 20px 25px;
		    &:first-child{
		    	padding-top: 0;
		    }
	    }
	}
	.product-item-actions{
		@include media-breakpoint-down(sm){
			margin-top: 10px;
			width: 100%;
		}
	}



	.item{
		padding: 25px 40px;
		border-bottom: 1px solid $light;
		@include media-breakpoint-down(lg){
			padding: 20px 30px;
		}
		@include media-breakpoint-down(xs){
			padding: 15px;
		}
		&:first-child{
			border-top: 1px solid $light;
		}
		&:last-child{
			border-bottom: 0;
		}
		.box_text_list{
			border: 2px solid $brand-primary;
			padding: 11px 20px;
			margin-top: 15px;
			width: 100%;
			display: block;
			margin-right: 25px;
			@include media-breakpoint-down(lg){
				padding: 10px 15px;
			}
			@include media-breakpoint-down(sm){
				margin-right: 0px;
			}

		}
	}
	img.product-image-photo{
		max-height: 250px;
   	 	width: auto;
   	 	max-width: 100%;
   	 	height: auto;
	}
}
.products-grid{
	.product-item-photo{
		img{
			max-height: 200px;
			width: auto;
		}
	}
	.product-item{
		border: 1px solid $light;
		border-top: 0;
		border-right: 0;
		padding: 30px;
		@include media-breakpoint-down(sm){
			padding: 17px;
		}
		&:nth-child(1), &:nth-child(2), &:nth-child(3),{
			border-top: 1px solid $light;
		}
		&:first-child, &:nth-child(4n){
			border-left: 0;
		}
		&:last-child{
			border-right: 1px solid $light;
		}
	}
	.product-item-info{
		@extend .d-flex;
		height: 100%;
	}
	.product-item-actions{
		margin-bottom: 0 !important;
	}

	.box_text_list{
		border: 2px solid $brand-primary;
		padding: 11px 20px;
		margin-top: 15px;
		width: 100%;
		margin-bottom: 15px;
		.delivery, .from{
			font-size: 0.9rem;
			display: block;
		}
	}
	.product-item-description, .product-item-name{
		font-size: 1rem;
	}

}
