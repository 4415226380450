input, textarea, keygen, select, button, meter, progress{
	font: 16px "proxima-nova",Helvetica,sans-serif;
}
.form-check-label{
	font-weight: 600;
}
.form-control{
	border-radius: 0;
	border-width: 2px;
	box-shadow: none;
	cursor: pointer;
	&:focus{
		border-color: $brand-primary;
	}
}

select.form-control:not([size]):not([multiple]), .field select:not([size]):not([multiple]) {
    height: calc(2.18rem + 5px);
}

.form-group{
	label{
		cursor: pointer;
		font-weight: 600;
		color: $secondary-color;
	}
}

input{
	outline: 0 !important;
	&.item-qty{
		border: 0;
		background: $light;
		box-shadow: none;
		line-height: 27px;
		border: 1px solid #d3d3d3;
	}
}



input, textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    resize: none;
}
.form-group{
	select.form-control {
	  -webkit-appearance: none;
	  -webkit-border-radius: 0px;
	  @include background-image(arrow-blue);
	  background-repeat: no-repeat;
	  background-position: 97% center;
	  background-size: 15px 15px;
	}
}


// AUTHENTICATION POPUP

	.block-authentication{
		.field{
			@extend .form-group;
			input{
				@extend .form-control;
			}
		}
	}

// FORMS

	.field{
		@extend .form-group;
		select{
			@extend .form-control;
		}
		input:not(.radio):not(.checkbox){
			@extend .form-control;
		}
		.radio, .checkbox{
			@extend .form-check;
		}
		&.choice{
			label:hover:before {
			  border: 2px solid darken(#ccc,5%) !important;
			}
			label{
				span{
					font-weight: 700;
				}
			}
		}
	}



	/* Base for label styling */
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
	  position: absolute;
	  left: -9999px;
	}
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
	  position: relative;
	  padding-left: 1.95em;
	  cursor: pointer;
	}

	/* checkbox aspect */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
	  content: '';
	  position: absolute;
	  left: 0; top: 0;
	  width: 1.25em; height: 1.25em;
	  border: 2px solid #ccc;
	  background: #fff;
	}
	/* checked mark aspect */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
	  content: '✔';
	  position: absolute;
	  top: .25em;
	  left: .3em;
	  font-size: 1em;
	  line-height: 0.8;
	  color: $brand-primary;
	  transition: all .2s;
	}
	/* checked mark aspect changes */
	[type="checkbox"]:not(:checked) + label:after {
	  opacity: 0;
	  transform: scale(0);
	}
	[type="checkbox"]:checked + label:after {
	  opacity: 1;
	  transform: scale(1);
	}
	/* disabled checkbox */
	[type="checkbox"]:disabled:not(:checked) + label:before,
	[type="checkbox"]:disabled:checked + label:before {
	  box-shadow: none;
	  border-color: #bbb;
	  background-color: #ddd;
	}
	[type="checkbox"]:disabled:checked + label:after {
	  color: #999;
	}
	[type="checkbox"]:disabled + label {
	  color: #aaa;
	}
	/* accessibility */
	[type="checkbox"]:checked:focus + label:before,
	[type="checkbox"]:not(:checked):focus + label:before {
	  border: 2px solid #ccc;
	}



.block-authentication{
	color: $secondary-color;
}
.form{
	font-size: 1rem;
}



.fieldset > .field.required > .label:after, 
.fieldset > .fields > .field.required > .label:after,
.fieldset > .form-group.required > label:after
{
    content: '*';
    color: #ec5845;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: 15px;
    margin: 0 0 0 5px;
}
.login-container .fieldset:after {
    border: none;
    margin: 10px 0 0;
    padding: inherit;
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    word-spacing: normal;
    color: #ec5845;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: 12px;
}

// PASSWORD CHECKER



	.field.password .control {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: column;
	          flex-direction: column; 
	}
	.field.password .control .mage-error {
	    -webkit-box-ordinal-group: 3;
	        -ms-flex-order: 2;
	            order: 2; 
	}
	.field.password .control .input-text {
	    -webkit-box-ordinal-group: 1;
	        -ms-flex-order: 0;
	            order: 0; 
	}

	.password-strength-meter {
	  background-color: #f6f3f4;
	  font-size: 13px;
	  height: 32px;
	  line-height: 32px;
	  padding: 0 9px;
	  position: relative;
	  z-index: 1; 
	}
	.password-strength-meter:before {
	    content: '';
	    height: 100%;
	    left: 0;
	    position: absolute;
	    top: 0;
	    z-index: -1; 
	}
	.password-strength-meter-0 .password-strength-meter:before {
	    background-color: #f6f3f4;
	    width: 100%; 
	}
	.password-strength-meter-1 .password-strength-meter:before {
	    background-color: #ffafae;
	    width: 25%; 
	}
	.password-strength-meter-2 .password-strength-meter:before {
	    background-color: #ffd6b3;
	    width: 50%; 
	}
	.password-strength-meter-3 .password-strength-meter:before {
	    background-color: #c5eeac;
	    width: 75%; 
	}
	.password-strength-meter-4 .password-strength-meter:before {
	    background-color: #81b562;
	    width: 100%; 
	}


	div.mage-error {
		margin-top: 7px;
	    color: $brand-primary;
	    font-family: inherit;
	    font-style: inherit;
	    font-weight: inherit;
	    line-height: inherit;
	    font-size: 12px;
	    display: inline-block;
	    text-decoration: none;
	    &:before{
	    	-webkit-font-smoothing: antialiased;
		    font-size: 24px;
		    line-height: 24px;
		    color: $brand-primary;
		    content: "";
		    font-family: "icons-blank-theme";
		    margin: 0;
		    display: inline-block;
		    font-weight: normal;
		    overflow: hidden;
		    speak: none;
		    text-align: center;
		    vertical-align: middle;
	    }
	}



// CREATE ACCOUNT

	.form-create-account, .form-address-edit, .password.forget, .form-edit-account {
		max-width: $max-form-width;
		.note{
			margin-bottom: 1rem;
		}
		.field {
			@extend .row;
			label.label{
				@extend .col-md-4;
				@extend .col-form-label;
			}
			.control{
				@extend .col-md-8;
			}
			.nested{
				margin-top: 1rem;
				.additional{
					label{
						display: none;
					}
					.control{
						@extend .col-md-12;
					}
				}
			}
		}
	}


// NOTES

	._with-tooltip{
		position: relative;
	}

	.fieldset > .field .note, .fieldset > .fields > .field .note {
	    color: inherit;
	    font-family: inherit;
	    font-style: inherit;
	    font-weight: inherit;
	    line-height: inherit;
	    font-size: 12px;
	    margin: 3px 0 0;
	    padding: 0;
	    display: inline-block;
	    text-decoration: none;
	}

	.fieldset > .field .note:before, .fieldset > .fields > .field .note:before {
	    -webkit-font-smoothing: antialiased;
	    font-size: 24px;
	    line-height: 6px;
	    color: inherit;
	    content: "";
	    font-family: "icons-blank-theme";
	    margin: inherit;
	    display: inline-block;
	    font-weight: normal;
	    overflow: hidden;
	    speak: none;
	    text-align: center;
	    vertical-align: top;
	}

	.abs-field-tooltip input, .field .control._with-tooltip input {
	    margin-right: 10px;
	    width: calc(100% - (21px + 10px + 5px));
	    @include media-breakpoint-down(sm){
	    	width: 100%;
	    }

	}

	.field-tooltip {
	    cursor: pointer;
	    position: absolute;
	    right: 0;
	    top: 1px;
	    @include media-breakpoint-down(sm){
	    	display: none;
	    }
	    .label span{
	    	display: none;
	    }
	}
	.field-tooltip .field-tooltip-action {
	    display: inline-block;
	    text-decoration: none;
	    @include media-breakpoint-down(sm){
	    	display: none;
	    }
	    &:hover{
	    	&:before{
	    		color: $brand-primary;
	    	}
	    }
	}

	.field-tooltip .field-tooltip-action:before {
	    -webkit-font-smoothing: antialiased;
	    font-size: 25px;
	    line-height: inherit;
	    color: #bbb;
	    content: "";
	    font-family: "icons-blank-theme";
	    margin: inherit;
	    display: inline-block;
	    font-weight: normal;
	    overflow: hidden;
	    speak: none;
	    text-align: center;
	    vertical-align: middle;
	    margin-right: 19px;
	}

	.field-tooltip .field-tooltip-content {
	    background: $secondary-color;
	    border-radius: 1px;
	    font-size: 14px;
	    padding: 12px;
	    width: 270px;
	    display: none;
	    left: 38px;
	    color: $white;
	    position: absolute;
	    text-transform: none;
	    top: -4px;
	    word-wrap: break-word;
	    z-index: 2;
	}

	.field-tooltip .field-tooltip-content:after {
	    border-right-color: $secondary-color;
	    width: 1px;
	    z-index: 4;
	}
	.field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
	    border: 10px solid transparent;
	    height: 0;
	    width: 0;
	    border-right-color: $secondary-color;
	    left: -20px;
	    top: 12px;
	    content: '';
	    display: block;
	    position: absolute;
	    z-index: 3;
	}
	.field-tooltip._active .field-tooltip-content {
	    display: block;
	}

// RADIO BUTTONS

	.radio, .field.choice {
	  margin: 0.5rem;
	  label{
	  	margin-bottom: 0;
	  }
	  input[type="radio"] {
	    position: absolute;
	    opacity: 0;
	    + .radio-label, + .label {
	      &:before {
	        content: '';
	        background: $white;
	        border-radius: 100%;
	        border: 1px solid darken($light, 25%);
	        display: inline-block;
	        width: 1.2em;
	        height: 1.2em;
	        position: relative;
	        top: -0.2em;
	        margin-right: 1em; 
	        vertical-align: top;
	        cursor: pointer;
	        text-align: center;
	        transition: all 250ms ease;
	      }
	    }
	    &:checked {
	      + .radio-label, + .label {
	        &:before {
	          background-color: $brand-primary;
	          box-shadow: inset 0 0 0 4px $white;
	        }
	      }
	    }
	    &:focus {
	      + .radio-label, + .label {
	        &:before {
	          outline: none;
	          border-color: $brand-primary;
	        }
	      }
	    }

	    + .radio-label, + .label {
	      &:empty {
	        &:before {
	          margin-right: 0;
	        }
	      }
	    }
	  }
	}
