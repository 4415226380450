.cart.table-wrapper .col.qty .label{
	@extend %visuallyhidden;
}

.cart-empty{
	font-size: 0.9rem;
}
.cart-container{
		@extend .row;

    @include media-breakpoint-down(md){
        margin-right: 0;
    }
}

.cart{
    .product-item-details{
        width: auto;
    }
	.subtotal{
		.price{
			font-weight: 600;
		}
	}
	&.actions{
		@extend %clearfix;
		.btn-primary{
			float: left;
		}
		.btn-secondary{
			margin-left: 15px;
			float: right;
		}
	}

	.item-actions{
		.actions-toolbar{
			margin-top: 0.5rem !important;
			margin-bottom: 12px;
		}
	}
	.item{
		.item-info{
			padding-top: 15px;
		}
	}

}

.cart.table-wrapper .item .col{
    padding: 27px 8px 10px;
}

.cart-summary{
    background: $light;
    margin-bottom: 0;
    padding: 20px 15px 25px;
    .inner-cart-summary{
    	padding: 0 10px;
    }
    #co-shipping-method-form{
    	font-size: 1rem;
    	.label{
    		&:before{
    			top: 0.1rem;
    		}
    	}
    }
    .summary.title{
    	display: block;
    	font-size: 1.4rem;
    }
    .message{
    	font-size: 0.9rem;
    	color: $brand-primary;
		span{
		    padding: 20px 0;
		    display: block;
    	}
    }
    .block{
    	&>.title{
		    border-top: 1px solid #d1d1d1;
		    cursor: pointer;
		    font-weight: 600;
		    display: block;
		    text-decoration: none;
		    margin-bottom: 0;
		    overflow: hidden;
		    padding: 7px 30px 7px 5px;
		    position: relative;
		    &:after{
			    -webkit-font-smoothing: antialiased;
			    font-size: 30px;
			    line-height: inherit;
			    color: inherit;
			    content: "";
			    font-family: "icons-blank-theme";
			    margin: inherit;
			    display: inline-block;
			    font-weight: normal;
			    overflow: hidden;
			    speak: none;
			    text-align: center;
			    vertical-align: middle;
		    	position: absolute;
			    right: -5px;
			    top: -5px;
		    }
    	}
    	&.active{
    		&>.title{
    			&:after{
    				content: "";
    			}
    		}
    	}
    	.fieldset{
    		.field{
    			&.note{
    				font-size: 13px;
    			}
    		}
    	}
    }
    .actions-toolbar{
    	margin-top: 0 !important;
    	margin-bottom: 10px;
    }
    .checkout-methods-items{
    	list-style: none;
    	padding-left: 0;
    	margin-bottom: 0;
    	text-align: center;
    	.primary, .multicheckout{
    		display: block;
    		margin-top: 20px;
    	}
    	.primary{
    		width: 100%;

    	}
    	.multicheckout{
    		font-weight: 700;
    		font-size: 1rem;
    	}
    }
}


.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .cart-summary .block .fieldset.estimate > .legend, .cart-summary .block .fieldset.estimate > .legend + br, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections, [class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle, .sidebar-additional .block-reorder .subtitle{
	display: none;
}

.cart.main.actions{
    @include media-breakpoint-down(xs){
        .btn{
            float: none !important;
            margin: 13px auto !important;
        }
    }
}
#shopping-cart-table{
    @include media-breakpoint-down(xs){
        display: block;
        thead{
            display: none;
        }
        td{
            display: block;
            float: left;
            padding: 5px;
            &:before{
                padding-right: 10px;
                content: attr(data-th) ": ";
                display: inline-block;
                color: $black;
                font-weight: 700;
            }
            .product-item-photo{
                 display: block !important;
            }
            .product-item-details{
                margin-left: 0 !important;
                .product-item-name{
                    margin-top: 0;
                }
            }

            .form-group.qty{
                display: inline-block;
            }

        }
        .item-actions{
            td{
                &:before{
                    display: none !important;
                }
                .btn{
                    margin-right: 10px !important;
                }
            }

        }
    }
}
#cart-totals .table-wrapper .data.table.totals td {
    max-width: 100px;
    width: 40%;
}