.messages{
    position: relative;
    width: 100%;
    .account &, .customer-account-login &{
      margin-bottom: 10px;
    }
    .message{
	    background: $brand-primary;
	    padding: 0.4rem .75rem;
	    font-size: 0.9rem;
	    color: $white;
	    margin-bottom: 10px;
	    &.error{
	    	background: $brand-primary;
	    }
	    &>div{
	    	
	    	display: block;
	    }
    }

    &.page{
    	.message{
    		padding: 0.4rem 30px;
        &.message-success{
          padding: 0.6rem 45px;
        }
    	}
    }
    
}
.message.empty{
  .account &{
    margin-bottom: 20px;
  }
}

.message.warning {
    border-radius: inherit;
    margin: 0 0;
    padding: 10px 20px;
    display: block;
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: 1.2;
    font-size: 13px;
    background: #f6f3f4;
    border-color: inherit;
    color: #383838;
    padding-left: 40px;
    position: relative;
}

.message.warning.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    line-height: 19px;
    color: #383838;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}



.message.info {
  border-radius: inherit;
  margin: 0 0;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #f6f3f4;
  border-color: inherit;
  color: #383838;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: rgba(255, 255, 255, 0.5); }
    .message.info a:hover {
      color: white; }
    .message.info a:active {
      color: #6fc4d1; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #383838;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  border-radius: inherit;
  margin: 0 0;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #ec5845;
  border-color: inherit;
  color: #fff !important;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: rgba(255, 255, 255, 0.5); }
    .message.error a:hover {
      color: white; }
    .message.error a:active {
      color: #6fc4d1; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 22px;
    line-height: 18px;
    color: #fff;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  border-radius: inherit;
  margin: 0 0;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #f6f3f4;
  border-color: inherit;
  color: #383838;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: rgba(255, 255, 255, 0.5); }
    .message.warning a:hover {
      color: white; }
    .message.warning a:active {
      color: #6fc4d1; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #383838;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  border-radius: inherit;
  margin: 0 0;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #f6f3f4;
  border-color: inherit;
  color: #383838;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: rgba(255, 255, 255, 0.5); }
    .message.notice a:hover {
      color: white; }
    .message.notice a:active {
      color: #6fc4d1; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #383838;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  border-radius: inherit;
  margin: 0 0;
  padding: 10px 20px;
  display: block;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1.2;
  font-size: 13px;
  background: #f6f3f4;
  border-color: inherit;
  color: #00585a;
  padding-left: 40px;
  position: relative; }
  .message.success a {
        color: #ec5845;
    font-weight: 600; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 28px;
    color: #00585a;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    bottom: inherit;
    left: 0;
    right: inherit;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }
.field .message.warning {
  margin-top: 10px; }
.catalogsearch-advanced-result .message.error {
  margin-top: -60px; }


.message{
    &.demo{
        background: darken($secondary-color,10);
        color: $white;
        display: block;
        width: 100%;
        text-align: center;
        .content{
            font-weight: 600;
            padding: 1rem 2rem;
        }
        @include media-breakpoint-down(md){
          display: none;
        }
    }
}