
.mage-dropdown-dialog{
	.slide-right{
	    position: fixed;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    width: 30vw;
	    max-width: 500px;
	    min-width: 300px;
	    z-index: 80;
	    background: $white;
	    @include transform(translateX(100%));
	    @include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
	    box-shadow: 0 19px 38px rgba(0,0,0,0), 0 15px 12px rgba(0,0,0,0);
	}	
	.active &{
		.slide-right{
		    @include transform(translateX(0));
		    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
		}	
	}
}
.paypal-express-in-context-mini-cart-container{
	margin-top: 25px;
}
#minicart-content-wrapper, .searchpopup{
	height: 100%;
	.subtotal{
		color: $secondary-color;
		text-align: right;
		    margin-bottom: 10px;
		.label, .amount{
			display: inline;
		}
		.amount{
			font-weight: 600;
		}
	}
	.block-title{
		padding: 30px;
		background: $light;
		position: relative;
		text-align: left;
		z-index: 10;
		@include media-breakpoint-down(sm){
			padding: 12px 20px;
		}
		.btn-close{
		    position: absolute;
		    right: 18px;
		    top: 23px;
		    @include media-breakpoint-down(sm){
		    	top: 3px;
		    	right: 0;
		    }
			span{
				color: $brand-primary;
    			line-height: 45px;
    			display: inline-block;
    			vertical-align: top;
    			font-size: 14px;
			}
			svg{
				width: 45px;
			    height: 45px;
    			display: inline-block;
    			vertical-align: top;
    			@include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
				path{
					fill: $brand-primary;
				}
			}
			&:hover{
				svg{
					@include transform(scale(1.1,1.1));
				}
			}
		}
		.text{
			font-size: 1.5rem;
			color: $secondary-color;
			@include media-breakpoint-down(sm){
				font-size: 1.2rem;
			}
			label{
				margin-bottom: 0;
			}
		}
		.qty{
			position: absolute;
			bottom: -15px;
			right: 30px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			display: block;
			border-radius: 999rem;
			background: $brand-primary;
			text-align: center;
			overflow: hidden;
			box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
			font-size: 15px;
			font-weight: 600;
			color: $white;

			&.empty{
				display: none;
			}
		}
	}
	.block-content, form{
		padding: 30px;
		position: relative;
		max-height: calc(100vh - 96px);
		overflow-y: auto;
		@include media-breakpoint-down(sm){
			max-height: calc(100vh - 52px);
			padding: 20px;
		}
	}
}

#minicart-content-wrapper{
	.action.primary{
		width: 100%;
	}
	.action.viewcart{
		font-weight: 600;
		text-align: right;
		margin-top: 10px;
		display: block;
	}
	.paypal{
		text-align: center;
    	margin-bottom: 20px;
	}
}
.searchpopup{

	.block-title{
		margin-right: 0 !important;
	}
}

.minicart-items-wrapper{	
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 279px !important;
    overflow: hidden;
    overflow-y: scroll;
    @include media-breakpoint-down(sm){
    	height: 200px !important;
    }
	.product-item-name{
		color: $secondary-color !important;
	}
	.product-item{
		padding: 22px 20px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		&:last-child{
			border-bottom: 0;
		}
	}
	.minicart-price{
		font-weight: 600;
		color: $brand-primary;
		font-size: 1rem;
	}
	.details-qty{
		float: right;
		label{
			margin-right: 8px;
			font-size: 14px;
		}
		input{
			max-width: 40px;
		    padding-left: 9px;
		    padding-right: 9px;
		    text-align: center;
		}
	}
	.product.actions{
		float: left;
    	width: 100%;
    	margin-top: 6px;
    	margin-bottom: 15px;
	}
	.action.delete{
	    font-weight: 100;
	    font-size: 13px;
	    float: right;
	    color: #777;
	    &:hover{
	    	color: $brand-primary;
	    }
	}
	.update-cart-item{
		border-width: 1px;
	    padding: 0.4rem 1rem;
	    font-size: 13px;
	    vertical-align: top;
	}
	.price-container{
		float: left;
	}
}


.cart.table-wrapper, .cart.actions{
		padding: 15px 15px;
		margin-bottom: 20px;
		.col.qty{
			.input-text{
				margin-top: -5px;
			    min-width: 60px;
			    padding: 7px 4px;
			}
		}
		.col.item{
			.product-item-photo, .product-item-details{
				display: inline-block;
				vertical-align: top;
				img{
					max-width: 60px;
					height: auto;
				}
			}
			.product-item-details{
				margin-left: 10px;
			}
		}
		.table-caption{
			display: none;
		}
}

.fieldset{
	&.coupon, &.estimate, &.rate{
		padding: 7px 30px 0 5px;
	}
}

.cart-empty{
	padding-bottom: 50px;
	padding-left: 30px;
}
.inner-cart-summary{
	position: relative;
	z-index: 1;
}

.cart-totals{
	.loading-mask{
		z-index: 5 !important;
	}
}

.amfinder-vertical, .amfinder-horizontal, .amfinder-common-wrapper{
	background: transparent !important;
	padding: 0 !important;
}



button, input{
	&.disabled{
			pointer-events: none;
			opacity: .5;
	}
}