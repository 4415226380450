.modes {
    display: block;
    margin-right: 20px;
    .modes-label{
    	@extend %visuallyhidden;
    }
    .modes-mode{
    	float: left;
    	font-weight: 400;
    	line-height: 1;
    	text-align: center;
    	display: block;
    	text-decoration: none;
    	&:before{
		    -webkit-font-smoothing: antialiased;
		    font-size: 26px;
		    line-height: inherit;
		    color: #fad0cb;
		    content: "";
		    font-family: "icons-blank-theme";
		    margin: inherit;
		    display: inline-block;
		    font-weight: normal;
		    overflow: hidden;
		    speak: none;
		    text-align: center;
		    vertical-align: middle;
    	}
    	&>span{
    		@extend %visuallyhidden;
    	}
    	&.mode-list{
    		&:before{
    			content: "";
    		}
    	}
    }
}