#search_autocomplete {
    position: relative !important;
    max-height: 500px;
    overflow-y: auto;

    ul{
    	@extend .no-list;
    	li{

		    display: block;
		    list-style: none;
		    opacity: 1;
		    margin: 0;
		    width: 100%;
		    background-color: $secondary-color;
		    z-index: 4;
		    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		    @include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
		    padding: 5px 30px;
		    cursor: pointer;
		    clear: both;
    		display: block;
    		height: 40px;
		    span{
			    font-size: 16px;
			    line-height: 30px;
			    display: inline-block;
			    vertical-align: top;
			    color: #fff;
			    float: left;
			    &.amount{
			    	font-size: 14px;
			    	display: inline-block;
			    	vertical-align: top;
			    	margin-right: 10px;
			    	color: rgba(255,255,255,0.5);
			    	float: right;
			    }
		    }
		    &:hover{
		    	background: darken($secondary-color, 5%);
		    }
    	}
    }
}

.minisearch{
	.nested{
		margin-top: 20px;
		font-weight: 600;
	}
}

.message.notice{
	float: left;
	width: 100%;
	a{
		color: rgba(0,0,0,0.5) !important;
		&:hover{
			color: rgba(0,0,0,1) !important;
		}
	}
}
.page-products-results-advanced{
	.toolbar{
		.modes{ display: none; }
	}
}
.search.summary{
	padding: 0 30px;
	margin-top: 16px;
	ul{
		list-style: none;
		padding-left: 0;
	}
}
