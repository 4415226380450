.table th, .table td{
	border-top: 0;
	padding: 0.5rem 0.75rem;

}
.mark{
	background: rgba(0,0,0,0.02);
}


table{
	&>caption{
		@extend %visuallyhidden;
	}
	.col{
		display: table-
	}
}




// MY ORDERS  - ACCOUNT

@include media-breakpoint-down(md){
	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td:before, .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th:before {
	    padding-right: 10px;
	    content: attr(data-th) ': ';
	    display: inline-block;
	    color: $black;
	    font-weight: 700;
	}

	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody > tr > td:first-child {
	    padding-top: 15px;
	}

	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr {
	    display: block;
	}

	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody {
	    display: block;
	}

	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td, .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th {
	    border-bottom: none;
	    display: block;
	    padding: 5px 10px;
	}
	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody > tr > td:last-child {
	    border-bottom: 1px solid #cccccc;
	    padding-bottom: 15px;
	}
	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > thead > tr > th {
	    display: none;
	}

}

.limiter {
    display: none;
}