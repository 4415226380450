.menu-hover-item{
	position: relative;
	&:after{
		content: '';
		width: 100%;
		height: 3px;
		background: $brand-primary;
		position: absolute;
		display: block;
		bottom: 0;
		@include opacity(0);
		@include transform(translateY(4px));
		@extend %transition;
	}
	&:hover{
		&:after{
			@include opacity(1);
			@include transform(translateY(0));
		}
	}
}

header.header{
	background: $white;
	@include media-breakpoint-down(sm){
	  *zoom: 1;
	  display: block;
	  &:before, &:after {
	    content: " ";
	    display: table;
	  }
	  &:after {
	    clear: both;
	  }
	}
	.content-header{
		@include media-breakpoint-down(sm){
			display: block;
			width: 100%;
		}
	}
	.brand{
		padding: 17px 35px;

		@include media-breakpoint-down(md){
			padding: 6px 15px;
		}
		@include media-breakpoint-down(sm){
			padding: 8px 17px;
			display: inline-block !important;
			float: left;
			width: auto;
		}
		@include media-breakpoint-down(xs){
			padding: 0;
			width: 100% !important
		}

		.logo{
			display: block;
			width: 100%;
			text-align: center;
			@include media-breakpoint-down(xs){
				text-align: center;
				img{
					max-height: 65px;
					margin-top: 10px;
				}
			}
			@include media-breakpoint-up(md){
				img{
					max-width: 280px;
				}
			}


		}
		.nav-toggle{
			display: none;
		}

		img{
			max-width: 100%;
			height: auto;
			@include media-breakpoint-down(sm){
				max-height: 57px;
				width: auto;
				height: auto;
			}
		}
	}
	.content{
		background: $secondary-color;
	    flex-flow: column;
	    align-items: stretch;

		@include media-breakpoint-down(sm){
			float: right;
			display: inline-block !important;
			width: auto !important;
			max-width: 100% !important;
		}
		@include media-breakpoint-down(xs){
			width: 100% !important;
			background: $white;
			display: flex !important;
			svg{
				path{
					fill: $secondary-color;
				}
			}
			.menu-trigger:before{
				background: $secondary-color;
				top: 3px;
				box-shadow: 0 0px rgba(52, 73, 94, 0), 0 9px $secondary-color, 0 9px rgba(52, 73, 94, 0), 0 18px $secondary-color;
			}

		}

		.greet{
			background: rgba(0,0,0,0.08);
			text-align: right;
			padding: 6px 40px;
			flex: 0 1 auto;
			span{
				color: $white;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.15em;
			}
			@include media-breakpoint-down(lg){
				font-size: 0.95rem;
			}
			@include media-breakpoint-down(md){
				display: none;
			}

		}
		.contentlinks{
			@extend .d-flex;
			@extend .justify-content-end;
			align-items: center;
			flex: 1 1 auto;
			padding: 12px 60px;
			@include transition(padding .2s ease-in-out);
			.iexplorer &{
				@extend .justify-content-start;
			}
			@include media-breakpoint-down(lg){
				padding: 12px 45px;
			}

			@include media-breakpoint-down(md){
				padding: 12px 28px;
			}
			@include media-breakpoint-down(xs){
				padding: 0 30px;
			    text-align: center;
			    margin: 0 auto;
			   	margin-top: 5px;
			    margin-bottom: 15px;
			    display: block !important;
			    .block-search, .minicart-wrapper, .menu-trigger{
					display: inline-block !important;
				    vertical-align: middle !important;
			    }
			    .menu-trigger{
			    	top: 0 !important;
			    }
			}


			&.stuck{
				@include media-breakpoint-up(md){
					position: fixed;
					z-index: 10;
					top: 0;
					right: 0;
					padding: 36px 60px;
					background: rgba(0,0,0,0.05);
				}

				@include media-breakpoint-down(lg){
					padding: 28px 45px;
				}
				@include media-breakpoint-down(md){
					padding: 32px 45px;
				}

				nav.navigation{
					display: none !important
				}	
				ul.header.links{
					display: none !important
				}
				.block-search{
					display: none;
				}
			}


		}

		.navigation{
			@extend .mr-auto;

			@include media-breakpoint-down(md){
				display: none;
			}
			ul{
				list-style: none;
				margin-bottom: 0;
				padding-left: 0;
				height: 100%;
				li{
					display: inline-block;
					padding-left: 20px;
					padding-right: 20px;
					a, strong{
						color: $white;
						font-weight: 600;
						padding: 4px 0;
						font-size: 1.05em;
						display: block;
						@include media-breakpoint-down(lg){
							font-size: 1rem;
						}
					}
					a{
						@extend .menu-hover-item;
					}
					strong{
						color: rgba($white,0.4);
					}
				}
			}
		}
		ul.header.links{
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			margin-right: 20px;
			.iexplorer &{
				margin-right: auto;
			}
			@include media-breakpoint-down(sm){
				display: none !important;
			}
			li{
				display: inline-block;
				vertical-align: top;
				padding-left: 15px;
				padding-right: 15px;
				a{
					color: $white;
					font-weight: 600;
					padding: 4px 0;
					display: block;
					font-size: 1.05em;
					@extend .menu-hover-item;
					@include media-breakpoint-down(lg){
						font-size: 1rem;
					}
				}
			}
			span.separator{
				color: $white;
				font-weight: 100;
				display: inline-block;
				vertical-align: top;
				padding: 13px 0;
			}
		}
		.block-search{
			svg{
				margin-top: 5px;
				width: 36px;
				height: 36px;
				@include transform(scale(0.9));
				@include transition(.5s ease-in-out);
			}
			.block-title{
				margin-right: 20px;
				cursor: pointer;
				&:hover{
					svg{
						@include transform(scale(1));
					}
				}
			}
			.block-content{
				position: fixed;
			}
		}
		.minicart-wrapper{
			position: relative;


			input[type="number"] {
			    -moz-appearance: textfield;
			}
			.icon{
				svg{
					margin-top: 2px;
					width: 40px;
					height: 40px;
				}
			}

			.counter.qty{
				position: absolute;
				bottom: 0;
				right: -12px;
				width: 30px;
				height: 30px;
				display: block;
				border-radius: 999rem;
				background: $white;
				text-align: center;
				overflow: hidden;
				box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
				@include media-breakpoint-down(xs){
					background: $secondary-color;
				}
				.loading-mask{
					background: transparent !important;
				}
				.counter-number{
					position: absolute;
					top: 50%;
					font-size: 15px;
    				font-weight: 600;
    				color: $black;
					left: 50%;
					@include transform(translate(-50%,-50%));
					@include media-breakpoint-down(xs){
						color: $white;
					}
				}
				.loading-mask{
					.loader{
						img{
							width: 22px;
    						height: auto;
    						z-index: 8;
						}
					}
				}

			}
		}
	}
}


.premain{
	&.stuck{
		@include media-breakpoint-up(md){
			position: fixed;
		    top: 0;
		    left: 50%;
		    right: 0;
		    z-index: 9;
		    max-width: 2000px;
		    width: 100%;
		    @include transform(translateX(-50%));
		}
	}
}
.mage-dropdown-dialog{
	position: relative;
	z-index: 100;
}

		
@media only screen and (min-width: 2000px){
	header.header .content .contentlinks.stuck{
		display: none !important;
	}
}



// MOBILE MENU

/*html, body, .main-content__body, .scroller {
    height: 100%;
}*/

.main-content__body{
	@include media-breakpoint-down(md){
		overflow: hidden;
	}
}

.scroller {
	@include media-breakpoint-down(md){
		overflow-y: auto;
	}
}

.scroller,
.scroller-inner {
	position: relative;
}

.menu-trigger {
    position: relative;
    padding-left: 34px;
    font-size: 0.9em;
    display: block;
    height: 27px;
    margin-left: 28px;
    @include media-breakpoint-up(lg){
    	display: none;
    }
}

.menu-trigger:before {
	position: absolute;
    top: 2px;
    left: 0;
    width: 33px;
    height: 2px;
    background: #fff;
    box-shadow: 0 0px rgba(52, 73, 94, 0), 0 10px #fff, 0 9px rgba(52, 73, 94, 0), 0 20px #fff;
    content: '';
}

.mp-pusher {
	position: relative;
	left: 0;
	height: 100%;
	@extend %transition;
	.show-menu &{
		@include media-breakpoint-down(md){
			@include transform(translateX(300px));
		}
		@include media-breakpoint-down(xs){
			@include transform(translateX(280px));
		}
	}


	.mp-menu{
		background: $secondary-color;
		.greet{
			background: darken($secondary-color,5);
			color: $white;
			padding: 6px 15px;
			font-weight: 600;
			font-size: 0.8rem;
			letter-spacing: 1px;
			text-align: center;
		}
		ul{
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
		}
		.mb-mainmenu{
			margin-bottom: 20px;
			li{
				
				border-bottom: 1px solid rgba(255,255,255,0.1);
				text-transform: uppercase;
				letter-spacing: 0.15rem;
				a,strong{
					display: block;
					padding: 0.75rem 2rem;
					color: $white;
					font-size: 0.9rem;
				}
			}

			strong{
				background: rgba(0,0,0,0.2);
			}
			a{
				font-weight: 600;
				&:focus, &:active{
					background: rgba(0,0,0,0.2);
				}
			}
		}
		.mb-accountmenu{
			li{
				a{
					padding: 0.5rem 2rem;
					color: $white;
					display: block;
				}
			}
		}

	}
}

.mp-menu {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 300px;
	height: 100%;
	overflow-y: auto;
	@include transform(translateX(-100%));
    @include media-breakpoint-up(lg){
    	display: none;
    }
    @include media-breakpoint-down(xs){
    	width: 280px;
    }

}

.mp-pusher {
	@include transition(all 0.5s);
}




.toggle-supplier__finder{
	display: block;
	background: $brand-primary;
	color: $white;
	font-weight: 600;
	letter-spacing: 0.1rem;
	font-size: 0.9rem;
	padding: 10px 25px;
	position: relative;
	text-transform: uppercase;
	&:active, &:hover, &:focus{
		color: $white;
	}
	@include media-breakpoint-up(md){
		display: none;
	}
	span{
		float: right;
	    background-image: url(../images/arrow-white.svg);
	    background-size: contain;
	    background-repeat: no-repeat;
	    position: absolute;
	    right: 30px;
	    top: 24%;
	    width: 20px;
	    height: 20px;
	    z-index: 100;
	    -webkit-transform: rotate(90deg);
	    transform: rotate(90deg);
	    -webkit-transform-origin: 50% 50%;
	    transform-origin: 50% 50%;
	    transition: all 0.3s ease-in-out;

	}
	&.active{
		span{
			@include transform(rotate(-90deg));
		}
	}
}


.mp-level{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $secondary-color;
    @include transform(translateX(-100%));
    @extend %transition;
    &.opened{
    	@include transform(translateX(0));
    }
    h4{
    	display: block;
    	color: $white;
    	position: relative;
    	padding: 20px;
    	font-size: 1rem;
    	text-transform: uppercase;
    	font-weight: 600;
    	letter-spacing: 0.1rem;
    	margin-bottom: 0;
    	span{
		    float: right;
		    background-image: url(../images/arrow-white.svg);
		    background-size: contain;
		    background-repeat: no-repeat;
		    position: absolute;
		    right: 20px;
		    top: 33%;
		    width: 16px;
		    height: 16px;
		    z-index: 100;
    	}
    }
    ul{
    	background: $white;
    	padding: 10px 20px !important;
    	li{
    		padding: 5px 0;
    		display: block;
    		font-size: 0.85rem;
    		a{
			    color: $secondary-color;
			    font-weight: 600;
			    display: inline-block;
			    position: relative;
			    span{
			    	&:after{
    					background: $brand-primary;
					    content: '';
					    width: 100%;
					    height: 3px;
					    display: block;
					    position: absolute;
					    bottom: -5px;
					    @include opacity(0);
					    @extend %transition;
					}
			    }
			    &:hover{
			    	span{
    					&:after{
    						@include opacity(1);
    						@include transform(translateY(-5px));
    					}
			    	}
			    }
    		}
    		&.active{
    			a{
    				span{
    					&:after{
    						@include opacity(1);
    						@include transform(translateY(-5px));
    					}
    				}
    			}
    		}
    	}
    }
}

// .block-search, .triggerSearch{
// 	display: none !important;
// }