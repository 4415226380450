// Colors


$brand-primary: #ec5845;
$secondary-color: #00585a;
$lightblue: #82ccd7;
$light: #f6f3f4;

$brand-primary__dark: darken($brand-primary, 35%);
$brand-primary__darker: darken($brand-primary, 13.5%); 
$brand-primary__lighter: lighten($brand-primary, 29%); 
$brand-primary__light: lighten($brand-primary, 45%);

$secondary__color: $secondary-color;
$secondary__color__light: lighten($secondary-color, 5%);

$black: #000;
$white: #fff;

$page__background-color: $white;
$panel__background-color: darken($page__background-color, 6%);

$active__color: $brand-primary;
$error__color: $brand-primary;

$color-sky-blue1: $lightblue;
$color-blue1: $lightblue;
$color-blue2: $lightblue;

$color-red9: $brand-primary;
$color-red10: $brand-primary;
$color-red11: $brand-primary;
$color-red12: $brand-primary;

$color-dark-green1: $secondary-color;


$color-gray-light0: #f6f6f6;
$color-gray-light01: #f4f4f4;
$color-gray-light1: #e5efe5;
$color-gray-light2: #bbb;
$color-gray-light3: #aeaeae;
$color-gray-light4: #cecece;
$color-gray-light5: #c1c1c1;
$color-gray-light6: #c5c5c5;

$color-gray-middle1: #e4e4e4;
$color-gray-middle2: #c6c6c6;
$color-gray-middle3: #7e807e;

$color-gray-darken0: #eee;
$color-gray-darken1: #e2e2e2;
$color-gray-darken2: #cdcdcd;
$color-gray-darken3: #555;
$color-gray-darken4: #494949;
$light1: $light;

$color-pink1: $brand-primary;
$color-dark-pink1: $brand-primary;




$icons-font: "icons-blank-theme";


$max-form-width: 700px;

// OTHER


$fa-font-path:          '~font-awesome/fonts';

$enable-flex:           true;
$enable-transitions:    true;
$enable-shadows:        true;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1540px
);

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 1030px,
  lg: 1200px,
  xl: 1600px
);



// FONT SIZES

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}




$image-path: '../images' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url('#{$image-path}/#{$name}.svg');
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url('#{$image-path}/#{$name}.#{$fallback-extension}');

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url('#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension}');
        }
    }
}




@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}


@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}



// BACKGROUND
@mixin gradient($args...) {
  background-image: -webkit-linear-gradient($args); 
  background-image:    -moz-linear-gradient($args); 
  background-image:     -ms-linear-gradient($args); 
  background-image:      -o-linear-gradient($args); 
  background-image:         linear-gradient($args);
}

// OPACITY

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// CLEARFIX

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// usage: @extend %clearfix;

// Visually hide an element

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

// usage @extend %visuallyhidden;



%transition {
  @include transition(all 300ms cubic-bezier(0.3, 0, 0.23, 0.76));
}