// CONTACT PAGE

	.form.contact{
		margin-top: 40px;
		.form-group{
				textarea{
					resize: none !important;
					min-height: 160px;
				}
		}
	}


// ADVANCED SEARCH

	.search.advanced{
		.form-group{
			.label{
				display: inline-block;
				vertical-align: bottom;
				text-overflow: ellipsis;
				width: 35%;
				float: left;
				line-height: 2.5rem;
				font-size: 1rem;
				@include media-breakpoint-down(sm){
					width: 100%;
					display: block;
					line-height: 1rem;
				}
			}
			.control{
				display: inline-block;
				vertical-align: bottom;
				width: 65%;
				@include media-breakpoint-down(sm){
					width: 100%;
					display: block;
				}
				textarea{
					resize: none !important;
					min-height: 160px;
				}
			}
		}
		.price{
			.control{

				.field{
					display: inline-block;
					vertical-align: top;
					.control{
						width: 100%;
							input{
								width: 60px;
							}

					}
					&.with-addon{
						input{
							display: inline-block;
							width: 60px;
						}
						label{
							display: inline-block;
							margin-left: 15px;
						}
					}
				}
			}
		}
	}


// CHECKOUT

	.form-login{
		position: relative;
		z-index: 1;
	}
	#checkoutSteps{
		@extend .container-fluid;
		width: 100%;
	}